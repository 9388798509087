/**
 * @license
 * Copyright 2017 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import * as concat_util from '../../ops/concat_util';
import {GPGPUProgram} from './gpgpu_math';

export class ConcatProgram implements GPGPUProgram {
  variableNames = ['A', 'B'];
  outputShape: number[] = [];
  userCode: string;

  // Concats 2d tensors along axis=1. See comments in MathBackendWebGL.concat().
  constructor(aShape: [number, number], bShape: [number, number]) {
    this.outputShape =
        concat_util.computeOutShape([aShape, bShape], 1 /* axis */);

    this.userCode = `
      void main() {
        ivec2 coords = getOutputCoords();
        int yR = coords.x;
        int yC = coords.y;

        float value = 0.0;
        if (yC < ${aShape[1]}) {
          value = getA(yR, yC);
        } else {
          yC -= ${aShape[1]};
          value = getB(yR, yC);
        }

        setOutput(value);
      }
    `;
  }
}
